import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { MultiSelect } from "react-multi-select-component";
import { getAll } from "@services/axaService";
import { getAll as getAllDoctors } from "@services/doctorsService";
import { getAll as getAllNurses } from "@services/nursesService";
import { update } from "@services/hospitalizationcrud";
import { storeMcMovementByCase } from "@services/mcMovementsService";
import { getAllCells } from "@services/cellService";
import { getAllConcierges } from "@services/conciergeService";
import {AxaCategoriesOthersNotVisible} from "@helpers/constants";

export const MedicAssignationTrakingMedic = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Asignacion Medico de Seguimiento
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentMedicAssignationTrakingMedic {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentMedicAssignationTrakingMedic = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Asignacion Medico de Seguimiento
      </Alert>

      <Card>
        <Card.Body>
          <MedicAssignationTrakingMedicForm {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const MedicAssignationTrakingMedicForm = (props) => {
  const [axas, setAxas] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [cells, setCells] = useState([]);
  const [concierges, setConcierges] = useState([]);
  const [axaCategories, setAxaCategories] = useState([]);

  const { id, slug } = props.data;

  useEffect(() => {
    getAll().then((response) => setAxas(response.data));
    getAllCells().then((response) => setCells(response.data));
    setAxaCategories(props.data.axaCategories?.split(',').map( category => ({label: category, value: category})) || []);

    /*let someElementIsVip = props.data.axaCategories?.split(',')?.some(category => category === "VIP");
    if (someElementIsVip) {

      getAllDoctors().then((response) => setDoctors(response.data?.filter(doctor =>doctor.category === "VIP")));
      getAllNurses().then((response) => setNurses(response.data?.filter(nurse =>nurse.category === "VIP")));
      getAllConcierges().then((response) => setConcierges(response.data?.filter(concierge =>concierge.category ==="VIP")));
    }*/

    getAllDoctors().then((response) => setDoctors(response.data));
    getAllNurses().then((response) => setNurses(response.data));
    getAllConcierges().then((response) => setConcierges(response.data));
  }, [props.data]);

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    values.doctorFollowId = parseInt(values.doctorFollowId) || "";
    values.nurseFollowId = parseInt(values.nurseFollowId) || "";
    values.axaCategories = values.axaCategoriesArray?.map( axaCategory => axaCategory.value).join(",") || "";
    storeMcMovementByCase(slug, values).then(response => {
      update(values, id).then((response) => {
        console.log("Form medic assignation traking inside saved")
      });
    });
  }

  const { bindSubmitForm, bindErrors } = props;

  const initialValues = {
    axaCategories: props.data.axaCategories || '',
    nurseFollowId: props.data.nurseFollowId || '',
    reassignmentMotive: props.mcMovement.reassignmentMotive || '',
    doctorFollowId: props.data.doctorFollowId || '',
    cellId: props.data.cellId || '',
    concergeId: props.data.concergeId || '',
    reasonMC: props.data.reasonMC || '',
  }

  const validationSchema = Yup.object().shape({
    doctorFollowId: Yup.string()
      .required("El campo es requerido"),
    nurseFollowId: Yup.string()
      .required("El campo es requerido"),
  });

  // const onChangeReassignCheck = (event) =>  {
  //   if (event.target.value === "si") setIsNotReassigned(false);
  //   else setIsNotReassigned(true);
  // }

  return (
    <Formik
      initialValues={{...initialValues, axaCategoriesArray: axaCategories}}
      enableReinitialize={true}
      onSubmit={handleSubmitForm}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        bindSubmitForm(submitForm);
        bindErrors(errors);
        return (
          <>
            <AlertError status={status} />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="axaCategoriesArray">
                    <Form.Label>Categoria AXA (Mesa de Control)</Form.Label>
                    <MultiSelect
                      name="axaCategoriesArray"
                      value={values.axaCategoriesArray}
                      disabled={true}
                      overrideStrings={
                        {
                          "allItemsAreSelected": "Todos los elementos fueron seleccionados.",
                          "clearSearch": "Limpiar busqueda",
                          "noOptions": "No hay opciones",
                          "search": "Buscar",
                          "selectAll": "Seleccionar Todas",
                          "selectSomeItems": "Seleccionar..."
                        }
                      }
                      onChange={(e) => {
                        setFieldValue("axaCategoriesArray", e);
                        /*let someElementIsVip = e.some(category => category.value === "VIP");
                        if (someElementIsVip) {
                          setNurses(nurses.filter(nurse =>nurse.category === "VIP"))
                          setDoctors(doctors.filter(doctor =>doctor.category === "VIP"))
                          setConcierges(concierges.filter(concierge =>concierge.category ==="VIP"))
                        }*/
                        getAllDoctors().then((response) => setDoctors(response.data));
                        getAllNurses().then((response) => setNurses(response.data));
                        getAllConcierges().then((response) => setConcierges(response.data));
                      }}
                      options={axas?.filter(axa => !AxaCategoriesOthersNotVisible.includes(axa.axaCatDescription))
                          .map( axa => ({ label: axa.axaCatDescription, value: axa.axaCatDescription})) || []}
                      labelledBy="Selecciona las categorias AXA"
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "axaCategoriesArray"
                      )}`}
                    />
                    <ErrorMessage name="axaCategoriesArray">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="nurseFollowId">
                    <Form.Label>Enfermera (o) Asignada (o) {props.data.caseType === "Alta hospitalaria" ? "*" : ""}</Form.Label>
                    <Form.Control
                      as="select"
                      name="nurseFollowId"
                      value={values.nurseFollowId || ""}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "nurseFollowId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {nurses?.map((nurse, idx) => (
                        <option key={idx} value={nurse.id}>
                          {nurse.fullName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="nurseFollowId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="doctorFollowId">
                    <Form.Label>Medico asignado *</Form.Label>
                    <Form.Control
                      as="select"
                      name="doctorFollowId"
                      value={values.doctorFollowId}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "doctorFollowId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {doctors?.map((doctor, idx) => (
                        <option key={idx} value={doctor.id}>
                          {doctor.fullName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="doctorFollowId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="concergeId">
                    <Form.Label>Concierge Asignado</Form.Label>
                    <Form.Control
                      as="select"
                      name="concergeId"
                      value={values.concergeId}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "concergeId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {concierges?.map((concierge, idx) => (
                        <option key={idx} value={concierge.id}>
                          {concierge.conciergeName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="concergeId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
                
                <Col>
                  <Form.Group controlId="cellId">
                    <Form.Label>Celula *</Form.Label>
                    <Form.Control
                      as="select"
                      name="cellId"
                      value={values.cellId}
                      onChange={handleChange}
                      disabled={true}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "cellId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {cells?.map((cell, idx) => (
                        <option key={idx} value={cell.id}>
                          {cell.cellDescription}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="cellId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row hidden={props.disabledAll}>
                <Col>
                  <Button variant="primary" type="submit">
                    Reasignar
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
