import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import RolesList from "./pages/roles/RolesList";
import PermissionsList from "./pages/permissions/PermissionsList";
import UsersList from "./pages/users/UsersList";
import ConciergesList from "./pages/concierges/ConciergesList";
import Hospitalization from "./pages/hospital/hospitalization/Hospitalization";
import HospitalizationCreate from "./pages/hospital/hospitalization/HospitalizationCreate";
import ControlDesk from "./pages/insurance/ControlDesk/ControlDesk";
import ControlDeskDetails from "./pages/insurance/ControlDesk/ControlDeskDetails";
import MedicalOpinion from "./pages/insurance/MedicalOpinion/MedicalOpinion";
import MedicalOpinionDetails from "./pages/insurance/MedicalOpinion/MedicalOpinionDetails";
import Tracing from "./pages/hospital/Tracing/Tracing";
import TracingDetails from "./pages/hospital/Tracing/TracingDetails";
import MedicalInformation from "./pages/hospital/MedicInformation/MedicalInformation";
import MedicalInformationDetails from "./pages/hospital/MedicInformation/MedicalInformationDetails";
import CostControl from "./pages/insurance/CostControl/CostControl";
import CostControlDetails from "./pages/insurance/CostControl/CostControlDetails";
import StorePatient from "./pages/hospital/StorePatient/StorePatient";
import StorePatientDetails from "./pages/hospital/StorePatient/StorePatientDetails";
import Reconsideration from "./pages/insurance/Reconsideration/Reconsideration";
import ReconsiderationDetails from "./pages/insurance/Reconsideration/ReconsiderationDetails";
import GeneralConsult from "./pages/hospital/GeneralConsult/GeneralConsult";
import GeneralConsultDetails from "./pages/hospital/GeneralConsult/GeneralConsultDetails";
import AlwaysWithYou from "./pages/insurance/AlwaysWithYou/AlwaysWithYou";
import AlwaysWithYouDetails from "./pages/insurance/AlwaysWithYou/AlwaysWithYouDetails";
import UpdateProcedure from "./pages/hospital/UpdateProcedure/UpdateProcedure";
import UpdateProcedureDetails from "./pages/hospital/UpdateProcedure/UpdateProcedureDetails";
import DoctorsList from "./pages/doctors/DoctorsList";
import NursesList from "./pages/nurses/NursesList";
import HospitalsList from "./pages/hospitals/HospitalsList";
import { ControlBoard } from "./pages/dashboard/ControlPanel";
import { ControlBoardDictum } from "./pages/dashboard/ControlBoardDictum";
import { ControlBoardCostControl } from "./pages/dashboard/ControlBoardCostControl";
import Tab from "./pages/insurance/Tab/Tab";
import TabDetails from "./pages/insurance/Tab/TabDetails";
import Requests from "./pages/insurance/Requests/Requests";
import CompletedCases from "./pages/insurance/CompletedCases/CompletedCases";
import CompletedCasesDetails from "./pages/insurance/CompletedCases/CompletedCasesDetails";
import CompletedCasesHospital from "./pages/hospital/CompletedCases/CompletedCases";
import CompletedCasesDetailsHospital from "./pages/hospital/CompletedCases/CompletedCasesDetails";
import CasesCanceled from "./pages/insurance/CasesCanceled/CasesCanceled";
import CasesCanceledDetails from "./pages/insurance/CasesCanceled/CasesCanceledDetails";
import OutHospitalFollowUp from "./pages/hospital/OutHospitalFollowUp/OutHospitalFollowUp";
import OutHospitalFollowUpDetails from "./pages/hospital/OutHospitalFollowUp/OutHospitalFollowUpDetails";

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                
                <ContentRoute path="/roles" permission="Roles" component={RolesList}/>
                
                <ContentRoute path="/privilegios" permission="Privilegios" component={PermissionsList}/>
                
                <ContentRoute path="/usuarios" permission="Usuarios" component={UsersList}/>

                <ContentRoute path="/doctores" permission="Doctores" component={DoctorsList}/>

                <ContentRoute path="/enfermeras" permission="Enfermeras" component={NursesList}/>

                <ContentRoute path="/hospitales" permission="Hospitales" component={HospitalsList}/>

                <ContentRoute path="/concierges" permission="Concierges" component={ConciergesList}/>

                <ContentRoute path="/ingreso_hospitalario" permission="Ingreso Hospitalario" component={HospitalizationCreate}/>
                <ContentRoute path="/ingreso_hospitalario_detalles/:slug" permission="Ingreso Hospitalario" component={Hospitalization}/>

                <ContentRoute path="/seguimiento" permission="Seguimiento" component={Tracing}/>
                <ContentRoute path="/seguimiento_detalles/:slug" permission="Seguimiento" component={TracingDetails}/>

                <ContentRoute path="/mesa_control" permission="Mesa de Control" component={ControlDesk}/>
                <ContentRoute path="/mesa_control_detalles/:slug" permission="Mesa de Control" component={ControlDeskDetails}/>

                <ContentRoute path="/dictamen_medico" permission="Dictamen Medico" component={MedicalOpinion}/>
                <ContentRoute path="/dictamen_medico_detalles/:slug" permission="Dictamen Medico" component={MedicalOpinionDetails}/>

                <ContentRoute path="/informacion_medica" permission="Informacion Medica" component={MedicalInformation}/>
                <ContentRoute path="/informacion_medica_detalles/:slug" permission="Informacion Medica" component={MedicalInformationDetails}/>

                <ContentRoute path="/control_costos" permission="Control de Costos" component={CostControl}/>
                <ContentRoute path="/control_costos_detalles/:slug" permission="Control de Costos" component={CostControlDetails}/>

                <ContentRoute path="/alta_paciente" permission="Alta Paciente" component={StorePatient}/>
                <ContentRoute path="/alta_paciente_detalles/:slug" permission="Alta Paciente" component={StorePatientDetails}/>

                <ContentRoute path="/reconsideraciones" permission="Reconsideraciones" component={Reconsideration}/>
                <ContentRoute path="/reconsideraciones_detalles/:slug" permission="Reconsideraciones" component={ReconsiderationDetails}/>

                <ContentRoute path="/consulta_general" permission="Consulta General" component={GeneralConsult}/>
                <ContentRoute path="/consulta_general_detalles/:slug" permission ="Consulta General" component={GeneralConsultDetails}/>

                <ContentRoute path="/actualizacion_tramite" permission="Actualizacion de Tramite" component={UpdateProcedure}/>
                <ContentRoute path="/actualizacion_tramite_detalles/:slug" permission ="Actualizacion de Tramite" component={UpdateProcedureDetails}/>

                <ContentRoute path="/24_7_contigo" permission="24/7 Contigo" component={AlwaysWithYou}/>
                <ContentRoute path="/24_7_contigo_detalles/:slug" permission="24/7 Contigo" component={AlwaysWithYouDetails}/>

                <ContentRoute path="/tablero_control" permission="Tablero de Control" component={ControlBoard}/>
                <ContentRoute path="/tablero_dictamen" permission="Tablero de Dictamen" component={ControlBoardDictum}/>
                <ContentRoute path="/tablero_control_costos" permission="Tablero de Control de Costos" component={ControlBoardCostControl}/>

                <ContentRoute path="/dictamen_extemporaneo" permission="Dictamen Extemporaneo" component={Tab}/>
                <ContentRoute path="/dictamen_extemporaneo_detalles/:slug" permission="Dictamen Extemporaneo" component={TabDetails}/>

                <ContentRoute path="/solicitudes" permission="Solicitudes" component={Requests}/>

                <ContentRoute path="/casos_finalizados" permission="Casos Finalizados" component={CompletedCases}/>
                <ContentRoute path="/casos_finalizados_detalles/:slug" permission="Casos Finalizados" component={CompletedCasesDetails}/>
                <ContentRoute path="/cancelaciones" permission="Cancelaciones" component={CasesCanceled}/>
                <ContentRoute path="/cancelaciones_detalles/:slug" permission="Cancelaciones" component={CasesCanceledDetails}/>

                <ContentRoute path="/casos_finalizados_hospital" permission="Casos Finalizados" component={CompletedCasesHospital}/>
                <ContentRoute path="/casos_finalizados_hospital_detalles/:slug" permission="Casos Finalizados" component={CompletedCasesDetailsHospital}/>

                <ContentRoute path="/seguimiento_extrahospitalario" permission="Casos Finalizados" component={OutHospitalFollowUp}/>
                <ContentRoute path="/seguimiento_extrahospitalario_detalles/:slug" permission="Casos Finalizados" component={OutHospitalFollowUpDetails}/>

                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
