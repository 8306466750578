import React, { useMemo } from "react";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { checkIsActive, IfHasPermission } from "../../../../_helpers";
import { useEffect } from "react";
import { useState } from "react";

export function AsideHospitalMenu({ isActive, modulesCounter }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const [casesMedicInformation, setCasesMedicInformation] = useState([]);
  const [casesStorePatient, setCasesStorePatient] = useState([]);
  const [casesTracing, setCasesTracing] = useState([]);
  const [casesUpdateProcedure, setCasesUpdateProcedure] = useState([]);
  
  useEffect(() => {
    setCasesMedicInformation(modulesCounter.medic_information);
    setCasesStorePatient(modulesCounter.store_patient);
    setCasesTracing(modulesCounter.tracing);
    setCasesUpdateProcedure(modulesCounter.update_procedure);
    
    
  }, [modulesCounter]);

  return (
    <div className={`tab-pane fade ${isActive && "show active"}`}>
      <div className="aside-menu-wrapper flex-column-fluid px-10 py-5">
        {/* begin::Menu Container */}
        <div
          id="kt_aside_menu"
          data-menu-vertical="1"
          className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}
        >
          {/* begin::Menu Nav */}
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {IfHasPermission("Ingreso Hospitalario") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/ingreso_hospitalario",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/ingreso_hospitalario">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-hospital-user" />
                  </span>
                  <span className="menu-text">Ingreso Hospitalario</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Seguimiento") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/seguimiento",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/seguimiento">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-notes-medical" />
                  </span>
                  <span className="menu-text">Seguimiento</span>
                  <span className="badge badge-secondary">{casesTracing}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Informacion Medica") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/informacion_medica",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/informacion_medica">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-question-circle" />
                  </span>
                  <span className="menu-text">Informacion Medica</span>
                  <span className="badge badge-secondary">{casesMedicInformation}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Actualizacion de Tramite") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/actualizacion_tramite",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/actualizacion_tramite">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-notes-medical" />
                  </span>
                  <span className="menu-text">Actualizacion de Tramite</span>
                  <span className="badge badge-secondary">{casesUpdateProcedure}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Alta Paciente") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/alta_paciente",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/alta_paciente">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-atlas" />
                  </span>
                  <span className="menu-text">Alta Paciente</span>
                  <span className="badge badge-secondary">{casesStorePatient}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Consulta General") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/consulta_general",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/consulta_general?modo=hospital">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-book-medical" />
                  </span>
                  <span className="menu-text">Consulta General</span>
                  {/* <span className="badge badge-secondary">{casesToday}</span> */}
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {IfHasPermission("Casos Finalizados") && (
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/casos_finalizados_hospital",
                        false
                    )}`}
                >
                  <NavLink className="menu-link" to="/casos_finalizados_hospital">
                    <span className="svg-icon menu-icon">
                      <Icon className="fa fa-check" />
                    </span>
                    <span className="menu-text">Solicitudes Extemporáneas</span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {IfHasPermission("Seguimiento Extrahospitalario") && (
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/seguimiento_extrahospitalario",
                        false
                    )}`}
                >
                  <NavLink className="menu-link" to="/seguimiento_extrahospitalario">
                    <span className="svg-icon menu-icon">
                      <Icon className="fa fa-undo" />
                    </span>
                    <span className="menu-text">Seguimiento Extrahospitalario</span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}
          </ul>

          {/* end::Menu Nav */}
        </div>
        {/* end::Menu Container */}
      </div>
    </div>
  );
}
