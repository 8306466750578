import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import {
  Alert,
  Col,
  Row,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import { getLasMcMovementtByCase } from "@services/mcMovementsService";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import { MedicAssigned } from "@pages/subPages/MedicAssigned/MedicAssigned";
import { FamilyData } from "@pages/subPages/FamilyData/FamilyData";
import { InsuranceData } from "@pages/subPages/InsuranceData/InsuranceData";
import { Opinion } from "@pages/subPages/Dictum/Dictum";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import {MedicalDataAdditional} from "@pages/subPages/AdditionalMedicalData/MedicalDataAdditional";
import {PROCEDURE_TYPES} from "../../../helpers/constants";

const TabDetails = (props) => {
  const subheader = useSubheader();
  const history = useHistory();

  const { slug } = props.match.params;

  const checkboxOptionsMedicalDataAdditional = [
    { value: "Nota de Evolución", label: "Nota de Evolución" },
    { value: "Informe Médico Actualizado", label: "Informe Médico Actualizado" },
    { value: "Nota Posquirúrgica", label: "Nota Posquirúrgica" },
    { value: "Acta Constitutiva", label: "Acta Constitutiva" },
    { value: "Carta Poder (Cobro de Honorarios Médicos)", label: "Carta Poder (Cobro de Honorarios Médicos)" },
    { value: "Nota de Ingreso", label: "Nota de Ingreso" },
    { value: "Nota de Egreso", label: "Nota de Egreso" },
    { value: "Estado de Cuenta", label: "Estado de Cuenta" }
  ];
  const selectOptions = {
    [PROCEDURE_TYPES.FEE_TABULATION]: [
      'Retabulación de Carta emitida',
      'Carta Solicitada no Adjuntada'
    ],
    [PROCEDURE_TYPES.VISA_VALIDATION_ATC]: [
      'Error de Facturación por el Hospital',
      'Rechazo de Pagos por inconsistencia en visado'
    ],
    [PROCEDURE_TYPES.AUTHORIZATION_LETTER_MODIFICATION]: [
      'Cambio de Nombre',
      'Monto Insuficiente',
      'Carta Rechazada por Pagos'
    ]
  };
  let submitFormMedicalDataAdditional = null;

  const [caseRecord, setCaseRecord] = useState({});
  const [, setMcMovement] = useState({});
  const [, setLetterMov] = useState({});
  const [totalLetters, setTotalLetters] = useState(0);
  const [caseType, setCaseType] = useState("");
  const [shouldBlockCase, setShouldBlockCase] = useState(true);
  const [note, setNote] = useState('');

  useEffect(() => {
    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      getLasMcMovementtByCase(response.data.id).then((currentMcMovemet) =>
        setMcMovement(currentMcMovemet.data)
      );
      getLastLetterMovByCase(response.data.id).then((currrentLetterMov) =>
        setLetterMov(currrentLetterMov.data)
      );

      setCaseType(response.data.caseType)
    });

    subheader.setTitle("Dictamen Extemporáneo");
  }, []);

  const bindSubmitFormMedicalDataAdditional = (submitForm) =>
      (submitFormMedicalDataAdditional = submitForm);

  const onClickSendTracingExtraHospitable = async () => {
    if (caseType === "") {
      alert("Debe actualizar el tipo de tramite");
      return;
    }

    if (!window.wasCommented) {
      alert("Debe ingresar un comentario");
      return;
    }

    let inputReasonDictum = document.getElementById("reason_sendToDictum").value;

    if (inputReasonDictum === "") {
      alert("Debe seleccionar una razon");
      return;
    }

    $("#btn_save_comment").click();
    submitFormMedicalDataAdditional();

    const dataUpdateCase =  {
      caseStatusId: 13,
      dictumReason: inputReasonDictum,
      caseType: caseType,
    };

    update(dataUpdateCase, caseRecord.id).then((response) => {
      history.push("/dictamen_extemporaneo");
    });

    setCaseRecord({ ...caseRecord, ...{ caseType } });
  };

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Dictamen Extemporáneo
      </Alert>

      <CaseHeader caseRecord={caseRecord}
          isSingleHeader={true}
          caseTypeSlot={
            <>
              <Row className="mt-2 align-items-center">
                <Col className="col-auto pr-0">
                  <b>Tipo de TRÁMITE: *</b>
                </Col>
                <Col className="col-auto">
                  <select
                      className="form-control"
                      name="caseTypeSelect"
                      value={caseType}
                      disabled={true}
                      onChange={e => {
                        setCaseType(e.target.value);
                        switch (e.target.value) {
                          case 'Tabulación de Honorarios':
                            setNote('Especificar la Información del Médico Tratante a Tabular en el Apartado de Honorarios Medicos. Adjunta la Documentación Actualizada en caso de ser necesario.');
                            break;
                          case 'Visado / Validación de ATC':
                            setNote('Especifica el Trámite solicitado en la Bitácora de Comentarios. Si necesitas un Nuevo Visado, por favor indica el Folio ATC.');
                            break;
                          case 'Modificación Carta de Autorización':
                            setNote('Especifica el Trámite solicitado en la Bitácora de Comentarios. Si necesitas un Nuevo Visado, por favor indica el Folio ATC.');
                            break;
                          default:
                            setNote('');
                            break;
                        }
                      }}
                  >
                    <option value="">SELECCIONE UNA OPCIÓN....</option>
                    <option value="Tabulación de Honorarios">
                      Tabulación de Honorarios
                    </option>
                    <option value="Visado / Validación de ATC">
                      Visado / Validación de ATC
                    </option>
                    <option value="Modificación Carta de Autorización">
                      Modificación Carta de Autorización
                    </option>
                  </select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col hidden={note === '' || note === null}>
                  <b>Nota: </b> {note}
                </Col>
              </Row>
            </>
          }
      />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="8" className="mt-5">
          <HospitalData
            icon="fa-search"
            eventKey="0"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={()=>{}}
          />
          <InssuredData
            icon="fa-search"
            eventKey="1"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={()=>{}}
          />
          <FamilyData
            icon="fa-search"
            eventKey="2"
            data={caseRecord}
            disabledAll={true}
            {...props}
            bindSubmitForm={()=>{}}
          />
          <MedicAssigned
            icon="fa-search"
            eventKey="3"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={()=>{}}
          />
          <InsuranceData
            icon="fa-search"
            eventKey="4"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={()=>{}}
          />
          <Opinion
            icon="fa-search"
            eventKey="5"
            data={caseRecord}
            disabledAll={true}
            totalLetters={totalLetters}
            bindSubmitForm={() => {}}
          />
          <AuthLetters
            showForm={true}
            icon="fa-pencil-alt"
            hideDeleteOption={true}
            eventKey="6"
            data={caseRecord}
            setTotalLetters={setTotalLetters}
            {...props}
          />
          <Documentation
            icon ="fa-pencil-alt"
            eventKey="7"
            data={caseRecord}
            disabledAll={false}
            typesDocument={[
              "Aviso de Accidente", "Carta de Autorización", "Comprobante de seguro o poliza", "Denuncia ante MP",
              "Desvíos Aplicados", "Estudios de Gabinete", "Estudios de Laboratorio", "Estudios que Confirmen DX",
              "Historia Clinica", "Honorarios Médicos", "Identificacion", "Informe Medico Actualizado", "Nota de Evolución",
              "Nota Postquirurgica", "Resumen Medico Actualizado", "Visado Final", "Otro",
            ]}
            {...props}
          />
          <MedicalFees
            icon="fa-pencil-alt"
            eventKey="8"
            data={caseRecord}
            filterStatuses={[12]}
            filterStatusesBycategorization={true}
            hideBtnInsurance={true}
            hideFormCreate={true}
            hideCptTabulate={true}
            hideStatus={true}
            showForm={true}
            hideTabsModuleInputs={false}
            {...props}
          />
          <MedicalDataAdditional
              icon ="fa-search"
              eventKey="9"
              data={caseRecord}
              checkboxOptions={checkboxOptionsMedicalDataAdditional}
              bindSubmitForm={bindSubmitFormMedicalDataAdditional}
          />
        </Accordion>
        <Accordion defaultActiveKey="0" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="0"
            data={caseRecord}
            disabledAll={false}
            eventStage="Dictamen Extemporáneo"
            isDifferentBackground={true}
            isPageable={true}
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>

      <Row>
        <Col md="4" className="mt-5">
          <Form.Control
              className="mb-5 mr-3"
              as="select"
              name="reason_sendToDictum"
              id="reason_sendToDictum"
          >
            <option value="">Selecciona una opción...</option>
            {selectOptions[caseType || caseRecord.caseType]?.map((option) => (
                <option key={option} value={option}>{option}</option>
            ))}
          </Form.Control>
          <Button variant="danger" onClick={onClickSendTracingExtraHospitable}>
            Enviar solicitud
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TabDetails;
