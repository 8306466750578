import React, {useEffect, useState} from "react";
import { Alert } from "react-bootstrap";
import { useSubheader } from "@metronic/layout";
import { useHistory } from "react-router-dom";
import { save } from "@services/hospitalizationcrud";
import { getCasesByStatuses } from "@services/casesService";
import Pagination from "react-js-pagination";
import GeneralTable from "@components/GeneralTable";
import GeneralFilter from "@components/GeneralFilter";
import CaseContext from "@contexts/CaseContext";

const HospitalizationCreate = () => {
  const subheader = useSubheader();

  const history = useHistory();
  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  useEffect(() => {
    subheader.setTitle("Ingreso hospitalario - Nueva solicitud");
  }, []);

  const getAllCasesByStatus = (pageNumber) => {
    getCasesByStatuses([], pageNumber).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const createCase = () => {
    save({ caseStatusId: 1 })
      .then((response) => {
        history.push(`/ingreso_hospitalario_detalles/${response.data.slug}`);
      })
      .catch((error) => {
        // swal("Good job!", "Ocurrio algun error", "error");
      });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Ingreso Hospitalario
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          result={casesRecord}
          statuses={[]}
          hideAxaCategoryId={true}
          hidePeriodFrom={true}
          hidePeriodTo={true}
          hideInsurance={true}
          hideHospital={true}
          hideMovementType={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
          hideComprehensiveCare={true}
          hideTypePolicy={true}
          hideAtcNumber={true}
          textButton="Consultar"
          hideButtonCreateCase={false}
          createCase={createCase}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/seguimiento_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideReloadPageButton={true}
        hideOptionsColumn={true}
        hideFlagsColumn={true}
        hideCounterColumn={true}
        hideAdmissionDateTimeColumn={true}
        hideDiagnosticColumn={true}
        hideReasonColumn={true}
        hideAssignedDoctorColumn={true}
        hideAssignedNurseColumn={true}
        hideAssignedConciergeColumn={true}
        hideAxaCategoryColumn={true}
        hideDaysStayColumn={true}
        hideMountsColumn={true}
        hideHospitalDateTimeHireColumn={true}
        hidePatientStatusColumn={true}
        hideTabulationColumn={true}
        assignationColumn=""
        reason="Tracing"
        currModule="Seguimiento"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default HospitalizationCreate;
