import React, { useEffect, useState } from "react";
import { Accordion, Alert, Button } from "react-bootstrap";
import { useSubheader } from "@metronic/layout";
import { FamilyData } from "@pages/subPages/FamilyData/FamilyData";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { MedicAssigned } from "@pages/subPages/MedicAssigned/MedicAssigned";
import { update, getBySlug } from "@services/hospitalizationcrud";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { setIn } from "formik";
import { isEmpty } from "@utils/checkObjectEmpty";
import moment from "moment";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";

const Hospitalization = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;
  const [caseRecord, setCaseRecord] = useState({});
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  const getCaseBySlug = () =>
    getBySlug(slug).then((response) => setCaseRecord(response.data));

  useEffect(() => {
    subheader.setTitle("Ingreso hospitalario");

    window.wasCommented = false;
    getCaseBySlug();
    
  }, []);

  let history = useHistory();

  const onClickControlDesk = async function(action = "createOrUpdate") {
    let dataForms = {};
    for (let pair of new FormData(
      document.getElementById("formu1")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    for (let pair of new FormData(
      document.getElementById("formu2")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    for (let pair of new FormData(
      document.getElementById("formu3")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    for (let pair of new FormData(
      document.getElementById("formu4")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    if (dataForms['vip'] === "Si") {
      dataForms['axaCategories'] = document.getElementById('axaCategories').value;
    }

    console.log(dataForms);

    const validationSchema = Yup.object().shape({
      incomeType: Yup.string().required(
        "El campo tipo de ingreso es requerido"
      ),
      caseType: Yup.string().required(
        "El campo tipo de TRÁMITE / movimiento es requerido"
      ),
      hospitalId: Yup.string().required("El campo hospital es requerido"),
      reporter: Yup.string()
        .required("El campo reportado por es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "reportado por" solo permite caracteres alfabéticos'
        ),
      hospitalEmail: Yup.string()
        .required("El campo email de hospital es requerido")
        .email("El campo email hospital parece no ser un valido"),
      hospitalPhone: Yup.string()
        .required("El campo TELÉFONO de hospital es requerido")
        .matches(
            /^[0-9]+$/,
            'El campo "TELÉFONO" solo permite caracteres numericos'
        ),
      hospitalExtension: Yup.string()
        .required("El campo EXTENSIÓN de hospital es requerido")
        .matches(
            /^[0-9]+$/,
            'El campo "EXTENSIÓN" solo permite caracteres numericos'
        ),
      assuredFlastname: Yup.string()
        .required("El campo apellido paterno asegurado es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "apellido paterno" solo permite caracteres alfabéticos'
        ),
      assuredMlastname: Yup.string()
        .required("El campo apellido materno asegurado es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "apellido materno" solo permite caracteres alfabéticos'
        ),
      assuredName: Yup.string()
        .required("El campo nombre asegurado es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "nombre asegurado" solo permite caracteres alfabéticos'
        ),
      assuredEmail: Yup.string()
        .required("El campo email asegurado es requerido")
        .email("El campo email asegurado parece no ser un valido"),
      incomeDatetime: Yup.string().required(
        "El campo fecha de ingreso es requerido"
      ),
      hospitalIden: Yup.string()
        .required("El campo folio hospital es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ0-9\s]+$/,
            'El campo "folio hospital" solo permite caracteres alfanuméicos'
        ),
      symptom: Yup.string()
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s,.;:]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "SÍNTOMA" solo permite caracteres alfabéticos',
            }
        ),
      diagnosis: Yup.string()
        .required("El campo DIAGNÓSTICO presuntivo es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            'El campo "DIAGNÓSTICO PRESUNTIVO" solo permite caracteres alfabéticos'
        ),
      familiarName: Yup.string()
          .required("El campo nombre es requerido")
          .matches(
              /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
              {
                excludeEmptyString: true,
                message: 'El campo "NOMBRE" solo permite caracteres alfabéticos',
              }
          ),
      relationship: Yup.string()
          .required("El campo parentesco es requerido"),
      familiarPhone: Yup.string()
          .required("El campo TELÉFONO fijo es requerido")
          .matches(
              /^[0-9]+$/,
              {
                excludeEmptyString: true,
                message: 'El campo "TELÉFONO FIJO" solo permite caracteres numericos',
              }
          ),
      familiarMobile: Yup.string()
          .required("El campo TELÉFONO celular es requerido")
          .matches(
              /^[0-9]+$/,
              {
                excludeEmptyString: true,
                message: 'El campo "TELÉFONO CELULAR" solo permite caracteres numericos',
              }
          ),
      familiarEmail: Yup.string()
          .required("El campo correo es requerido")
          .email("El campo no parece ser un email"),
      treatmentDoctorName: Yup.string()
        .required("El campo nombre MÉDICO tratante es obligatorio")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            'El campo "MÉDICO tratante" solo permite caracteres alfabéticos'
        ),
      treatmentDoctorPhone: Yup.string()
        .required("El campo TELÉFONO MÉDICO tratante es obligatorio")
        .matches(
            /^[0-9]+$/,
            'El campo "TELÉFONO" en MÉDICO tratante solo permite caracteres numericos'
        ),
      treatmentDoctorExt: Yup.string()
        .matches(
            /^[0-9]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "EXTENSIÓN" en MÉDICO tratante solo permite caracteres numericos',
            }
        ),
      treatmentDoctorEmail: Yup.string().required("El campo email MÉDICO tratante es obligatorio"),
    });

    try {
      await validationSchema.validate(dataForms, { abortEarly: false });

      dataForms = { ...caseRecord, ...dataForms };

      let timestamp= await axios.get('/cases/getTimestamp');
      dataForms["feamesaControl"] = moment.utc(timestamp.data).format();
      dataForms["caseStatusId"] = 2;
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      update(dataForms, caseRecord.id).then((response) => {
        setErrors({});
        setHasError(false);
        setCaseRecord({...caseRecord, ...dataForms});
        history.push("/ingreso_hospitalario");
      });
    } catch (err) {
      const errorsCatch = err.inner.reduce((formError, innerError) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      setErrors(errorsCatch);
      setHasError(true);
    }
  };

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Ingreso Hospitalario
      </Alert>

      <p>{caseRecord.caseTicket}</p>

      <Alert show={hasError} variant="danger" style={{ fontSize: "14px" }}>
        {Object.entries(errors).map((error, idx) => (
          <p key={idx}>{error[1]}</p>
        ))}
      </Alert>

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0">
          <HospitalData
            icon="fa-pencil-alt"
            eventKey="0"
            data={caseRecord}
            disabledAll={false}
            bindSubmitForm={() => {}}
          />
          <InssuredData
            icon="fa-pencil-alt"
            eventKey="1"
            data={caseRecord}
            disabledAll={false}
            bindSubmitForm={() => {}}
          />
          <FamilyData icon="fa-pencil-alt" eventKey="2" data={caseRecord} bindSubmitForm={() => {}} />
          <MedicAssigned
            icon="fa-pencil-alt"
            eventKey="3"
            data={caseRecord}
            bindSubmitForm={() => {}}
          />
          { !isEmpty(caseRecord) && <Documentation icon="fa-pencil-alt" eventKey="4" data={caseRecord} slug={caseRecord.slug} /> }
          { !isEmpty(caseRecord) && <Binnacle icon="fa-pencil-alt" eventKey="5" data={caseRecord} slug={caseRecord.slug} eventStage="Ingreso hospitalario" /> }
        </Accordion>
      </BlockCaseContext.Provider>

      <Button className="mt-5" variant="primary" onClick={ (e) => onClickControlDesk("update") }>
        Enviar a Mesa de Control
      </Button>
      {/* <Button className="mt-5 ml-5" variant="primary" onClick={ (e) => onClickControlDesk() }>Crear Caso</Button> */}
    </>
  );
};

export default Hospitalization;
