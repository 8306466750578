import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import GeneralFilter from "@components/GeneralFilter";
import Pagination from "react-js-pagination";
import { getCasesByStatuses } from "@services/casesService";
import GeneralTable from "@components/GeneralTable";
import CaseContext from "@contexts/CaseContext";

const Tab = () => {
  window.currModule = "Dictamen Extemporáneo";
  window.assignationColumn = "feaccostos";

  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const { nurse, role, permissions } = useSelector(
    ({ auth, nurse, role, permissions }) => ({
      nurse: auth.user?.nurse?.id,
      role: auth.user?.role,
      permissions: auth?.permissions,
    }),
    shallowEqual
  );

  const getAllCasesByStatus = (pageNumber) => {
    const queryParams = ['&byRegion=true', '&byCell=true'];

    getCasesByStatuses([12], pageNumber, queryParams).then((response) => {
      let hasDictumPermission = permissions.some(
        (permission) => permission.name === "Control de Costos"
      );
      if (
        hasDictumPermission &&
        role.name !== "Admin" &&
        role.name !== "ADMINAXA"
      ) {
        let filtered = response.data?.filter(
          (currCase) => currCase.nurseFollowId === nurse
        ); //|| (currCase.statusDictum === "Rechazo Definitivo" && currCase.caseStatusId === 6)
        setCasesRecord(filtered);
      } else {
        setCasesRecord(response.data);
      }

      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    getAllCasesByStatus(paramsPagination.activePage);

    subheader.setTitle("Dictamen Extemporáneo");
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Dictamen Extemporáneo
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[12]}
          hideInsurance={true}
          hideHospitalInvoice={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
          hideTypePolicy={true}
          hideRequiresTabulation={true}
          hideRegion={false}
          hideCell={false}
          caseTypeOptions={[
            { value: "Tabulacion de Honorarios", label: "Tabulación de Honorarios" },
            { value: "Visado o Validacion de ATC", label: "Visado / Validación de ATC" },
            { value: "Modificacion Carta de Autorizacion", label: "Modificación Carta de Autorización" },
          ]}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/dictamen_extemporaneo_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideExtemporaneousFlagColumn={false}
        hideFlagsColumn={true}
        hidePatientStatusColumn={true}
        hideAxaCategoryColumn={false}
        hideCelulaColumn={false}
        hideUserColumn={false}
        hideIngressType={true}
        reasonTitle="Estatus Dictamen"
        reason="Dictum"
        assignationColumn="feaccostos"
        currModule="Dictamen Extemporáneo"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default Tab;
